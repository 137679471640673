<template>
<div>
  <!-- slider Area Start-->
     <div class="slider-area ">
        <!-- Mobile Menu -->
        <div class="single-slider slider-height2 d-flex align-items-center" data-background="assets/img/hero/about.jpg">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap text-center">
                            <h2>EKONOMI KREATIF</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
     <!-- slider Area End-->
  <div class="favourite-place place-padding" style="margin-top: -5%">
    <div class="container">
      <!-- Section Tittle -->
      <div class="row">
        <div class="col-lg-12">
          <div class="section-tittle">
            <span>Ekonomi Kreatif</span>
            <h2>Produk Unggulan</h2>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top:-5%">
        <div class="col-md-6">
          <div class="form-group">
              <input @keyup="cari_data" v-model="cari_value" class="form-control valid" type="text" placeholder="Cari Produk"> 
          </div>

        </div>
        <div class="col-md-6">
          
        </div>
          
      </div>
      <br>
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6" v-for="(data, i) in list_data" :key="i">
          <div class="single-place mb-30">
            <div class="place-img">
             <img :src="$store.state.url.URL_APP+'uploads/'+data.file" alt="">
            </div>
            <div class="place-cap">
              <div class="place-cap-top">
                <h3><a href="javascript:void(0)" @click="routerToPlace('/wisataDetile?idx='+data.keyx)">{{data.brand}}</a></h3>
                <p class="dolor" style="margin-top:-5%"> <span>Rp.{{UMUM.formatMoney(data.harga)}}</span></p>
              </div>
              <div class="place-cap-bottom" style="margin-top:-12%">
                <ul>
                  <li><i class="far fa-clock"></i>{{data.ekokrafpelaku_pelaku}}</li> <br>
                  <li><i class="fas fa-map-marker-alt"></i>{{data.nama_des_kel}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr>
      <div class="text-center">
        <button @click="btn_prev" type="button" class="genric-btn primary-border medium">Prev</button>
        &nbsp;
        <span style="color:#444444; font-size:11px; font-weight:bold">
          {{page_first}} - {{page_last}}
        </span>
        &nbsp;
        <button @click="btn_next" type="button" class="genric-btn primary-border medium">Next</button>
      </div>
      <hr>

    </div>
  </div>

    <!-- <script type="application/javascript" defer src="./assets/js/vendor/jquery-1.12.4.min.js"></script> -->


    <script type="application/javascript" defer src="./assets/js/plugins.js"></script>
    <script type="application/javascript" defer src="./assets/js/main.js"></script>
</div>
</template>

<script>


import FETCHING from  '../../library/fetching'
import UMUM from  '../../library/umum'


export default {
  data() {
    return {
      list_jenis_wisata : [],
      bidangPariwisata : 2,


      list_data: [],
      page_first: 1,
      page_last: 0,
      cari_value: "",
      page_limit : 9,

      m_jenisPariwisata : '',

      FETCHING : FETCHING,
      UMUM : UMUM,


      loadJenis : false
    }
  },

  methods: {
    getView: function() {
      fetch(this.$store.state.url.URL_EKOKRAF_PELAKU_PRODUK + "view", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          m_jenisPariwisata : this.m_jenisPariwisata,
          data_ke: this.page_first,
          cari_value: this.cari_value,
          page_limit : this.page_limit,
        })
      })
        .then(res => res.json())
        .then(res_data => {
          this.list_data = res_data.data;
          this.page_last = res_data.jml_data;
            console.log(res_data);
        });
    },


    btn_prev: function() {
      if (this.page_first > 1) {
          this.page_first--;
      } else {
          this.page_first = 1;
      }
      this.getView();
    },

    btn_next: function() {
      if (this.page_first >= this.page_last) {
          this.page_first == this.page_last;
      } else {
          this.page_first++;
      }
      this.getView();
    },

    cari_data: function() {
      this.page_first = 1;
      this.getView();
    },

    fetcher : async function() {
      this.loadJenis = false
      this.list_jenis_wisata = await FETCHING.FETCH_POST(
        this.$store.state.url.URL_JENIS_WISATA + "list", 
        {bidangPariwisata : this.bidangPariwisata}
      )

      this.loadJenis = true

      // console.log(this.list_jenis_wisata)
    },
    routerToPlace(data){
            // console.log('telasooo')
            this.$router.push(data);
    },
  },

  mounted () {
    // this.fetcher()
    this.getView();
  },


};
</script>

<style>
</style>